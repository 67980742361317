import React from "react";
import { useStaticQuery, graphql } from "gatsby";

const ValueProps = ({ headingLevel, className }) => {
  const data = useStaticQuery(graphql`
    {
      icon1: file(
        relativePath: {
          eq: "0.0 Repeating Modules/Why Us/Personalized Experience.svg"
        }
      ) {
        publicURL
      }
      icon2: file(
        relativePath: {
          eq: "0.0 Repeating Modules/Why Us/Easy Loan Process.svg"
        }
      ) {
        publicURL
      }
      icon3: file(
        relativePath: {
          eq: "0.0 Repeating Modules/Why Us/Family Owned _ Operated.svg"
        }
      ) {
        publicURL
      }
      icon4: file(
        relativePath: {
          eq: "0.0 Repeating Modules/Why Us/Experts Since 1989.svg"
        }
      ) {
        publicURL
      }
    }
  `);

  const HeadingTag = headingLevel || "h2";

  const content = [
    {

      heading: "Fixed Rate",
      text: "A fixed-rate mortgage carries a set interest rate for the loan's lifetime, and market fluctuations will not impact the rate. As a result, your monthly mortgage payment will always remain the same, making budgeting easy.",
    },
    {

      heading: "Adjustable ARM",
      text: "There is an initial loan period with an adjustable-rate mortgage where you pay a fixed rate. Then the interest rate changes periodically throughout the remainder of the loan. The interest rate change depends on how the market has fluctuated.",
    },
    {

      heading: "Interest Only",
      text: "When you have an interest-only mortgage, you pay just the interest for the first several years of the loan. Then the principal is amortized into the payment schedule after that initial period. At the end of the interest-only period, you also have the option to make a balloon payment or refinance and get a new loan.",
    },
    {

      heading: "Graduated Payments",
      text: "The monthly payments on a graduated payment mortgage start at a set amount and gradually increase over time. This allows prospective homeowners to start with a lower monthly mortgage payment and can also be an option for those who might not otherwise qualify for a loan.",
    },
  ];

  return (
    <section
      className={`${
        className ||
        "md:p-[56px] mb-20 md:mb-16 py-16  "
      }`}
    >
      <div className="container text-center ">
        <header className="pt-[15px] md:py-10 ">
          <HeadingTag className="my-0 ">
            Your Mortgage Rate Options.
          </HeadingTag>
        </header>

        <div className="relative z-10 grid gap-y-10 py-[25px] md:grid-cols-2 md:gap-x-10 lg:gap-x-20">
          {content.map((content, i) => {
            return (
              <div className="flex " key={i}>
                <div className=" rounded-lg bg-white p-[14px] drop-shadow-xl drop-shadow-xl md:p-[24px]">
                  <p className="heading-six mb-3">{content.heading}</p>
                  <p className="mb-0">{content.text}</p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default ValueProps;
