import React from "react";
import { graphql, Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import Testimonials from "../components/Repeating/Testimonials";
import ValueProps from "../components/Repeating/ValueProps";
import RateOptions from "../components/Repeating/RateOptions";
import MortgageOptions from "../components/Repeating/MortgageOptions";
import ButtonSolid from "../components/Button/ButtonSolid";

import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemPanel,
  AccordionItemState,
} from "react-accessible-accordion";

import trendSVG from "../images/Landing Page/svg/trend.svg";
import cashSVG from "../images/Landing Page/svg/cash.svg";
import walletSVG from "../images/Landing Page/svg/wallet.svg";

const Page = ({ data }) => {
  // const faqs = {
  //   homeLoan: [
  //     {
  //       question: "How Much Equity Can I Tap Into?",
  //       answer: (
  //         <>
  //           <p className="mb-0">
  //             Lenders will typically allow you to borrow up to 85 percent of
  //             your home’s equity, which is the appraised value minus what you
  //             owe. This is what’s called loan-to-value (LTV).
  //           </p>
  //         </>
  //       ),
  //     },
  //     {
  //       question: "What Credit Score Do I Need?",
  //       answer: (
  //         <>
  //           <p className="mb-0">
  //             You’ll need a good credit score in the mid-600s or higher (over
  //             700 is ideal). If you need to improve your credit score, you can
  //             start by paying down credit card balances, catching up on past due
  //             accounts, and checking your credit report for errors.
  //           </p>
  //         </>
  //       ),
  //     },
  //     {
  //       question: "What About My Debt-to-Income Ratio?",
  //       answer: (
  //         <>
  //           <p className="mb-0">
  //             When applying for a HELOC, the lower your debt-to-income (DTI)
  //             ratio, the better (43% or lower is the guide). To calculate your
  //             DTI, add all your monthly debt payments and divide the total by
  //             your gross monthly income. Then multiply by 100 to get the
  //             percentage.
  //           </p>
  //         </>
  //       ),
  //     },
  //     {
  //       question: "What Type of Interest Rate Can I Expect?",
  //       answer: (
  //         <>
  //           <p>
  //             The interest rate on a HELOC is adjustable (unlike the fixed rate
  //             you get with a home equity loan) and will fluctuate with the
  //             market.
  //           </p>
  //         </>
  //       ),
  //     },
  //     {
  //       question: "How Does the Funding Work?",
  //       answer: (
  //         <>
  //           <p className="mb-0">
  //             You’ll receive a revolving line of credit if approved for a HELOC,
  //             which means you’ll be able to use that credit line, make payments,
  //             and take more money out as needed. A HELOC differs from a home
  //             equity loan, which gives you the loan proceeds all at once.
  //           </p>
  //         </>
  //       ),
  //     },
  //     {
  //       question: "Want More Info?",
  //       answer: (
  //         <>
  //           <p>
  //             Our mortgage advisors are happy to discuss HELOC in-depth to help
  //             you determine if it’s a good fit for you and your family.
  //           </p>
  //         </>
  //       ),
  //     },
  //   ],
  // };

  return (
    <Layout pageType={"Landing"}>
      <SearchEngineOptimization
        title=""
        description=""
        openGraphImage={data.openGraphImage.publicURL}
        twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
      />
      {/* full-width-hero */}
      {/* <section className="relative mb-16 overflow-hidden py-20 md:mb-24 md:py-56">
        <div className="absolute left-0 top-0 h-full w-full">
          <StaticImage
            src="../images/Landing Page/Hero.jpg"
            loading="eager"
            className="h-full"
            imgClassName="object-left"
          />
        </div>
        <div className="container">
          <div className="relative max-w-[730px]">
            <div className="mb-3.5 text-lg font-extrabold uppercase text-secondary-500">
              Trusted since 1989
            </div>
            <div className="mb-4 font-heading text-mobile-7xl font-extrabold text-white md:text-8xl">
              HELOC.<br></br>For that extra<br></br>cushion.
            </div>
            <p className="mb-0 text-xl font-light text-white md:mb-3 md:text-4xl">
              Make the most out of your home’s value.
            </p>
          </div>
        </div>
      </section> */}
      <section className="mb-20 pt-10 md:mb-28 md:pt-12">
        <div className="container">
          <div className="grid items-center gap-y-10 md:grid-cols-2 md:gap-x-10 lg:gap-x-20">
            <div>
              <div className="mb-3.5 text-lg font-extrabold uppercase text-secondary-500">
                Trusted since 1989
              </div>
              <h1 className=" lg:text-[75px] lg:leading-[85px]">
                Goal: Refinance.
              </h1>
              <p className=" text-black lg:text-[30px] lg:leading-[40px]">
                Get a cash out refi for a brand new start.
              </p>
              <ButtonSolid
                href="/request-rates/"
                text="Get Started"
                altStyle={2}
              />
            </div>
            <div>
              <StaticImage
                src="../images/common/refinance_hero_image.png"
                loading="eager"
                width={560}
              />
            </div>
          </div>
        </div>
      </section>
      <section className="mb-20 md:mb-26">
        <div className="container">
          <header className="mb-10 text-center md:mb-14">
            <h2>Put your home equity to work.</h2>
          </header>
          <div className="grid gap-y-8 md:grid-cols-3 md:gap-x-6 lg:gap-x-10">
            <div className="flex flex-col justify-between justify-between rounded-3xl bg-secondary-500/60 px-2 py-8 shadow-4xl">
              <div className="text-center">
                <img
                  src={cashSVG}
                  alt="Lower Your Payment"
                  className="mx-auto mb-6"
                />

                <h3 className="heading-five">Take Cash Out</h3>
              </div>

              <div className="mb-6 text-center ">
                <div className="border-t-2 border-solid border-transparent pb-[8px]"></div>
                <p className="mx-auto mb-0 w-9/12 py-[15px] text-typography-heading md:w-full md:py-[5px]">
                  Get a lump sum to tackle big projects, invest in property, or
                  pay for expenses.
                </p>
                <div className="relative flex items-center justify-center text-center md:py-[5px] md:py-[8px]">
                  <div className="mr-0 max-w-[35%] flex-grow  border-t border-[#001F3D] opacity-30 md:max-w-[25%]"></div>
                  <span className=" block py-[5px] px-[13px] text-sm font-bold capitalize text-[#001F3D] md:py-[5px] ">
                    good for
                  </span>
                  <div className="ml-0 max-w-[35%] flex-grow  border-t border-[#001F3D] opacity-30 md:max-w-[25%]"></div>
                </div>
                <div className="relative flex items-center justify-center text-center md:justify-start md:py-[5px] md:py-[8px]">
                  <div className="mr-0  hidden  flex-grow border-t border-transparent opacity-30 md:visible md:!block md:max-w-[8%]"></div>
                  <span className=" block py-[5px] px-[13px] text-sm font-bold capitalize text-[#001F3D] md:py-[5px] ">
                    <ul className="styled-list-checkmark text-color-[#001F3D] text-typography-heading">
                      <li>Renovating your home</li>
                      <li>Investing in another property</li>
                      <li>Paying for major expenses</li>
                    </ul>
                  </span>
                  {/* <div className="ml-0 max-w-[25%] flex-grow border-t border-[#001F3D] opacity-30"></div> */}
                </div>
              </div>
              <ButtonSolid
                href="/refinance/"
                text="Get Started Now!"
                className="!text-md mx-auto w-9/12"
              />
            </div>
            <div className="flex flex-col justify-between justify-between rounded-3xl bg-secondary-500/60 px-2 py-8 shadow-4xl">
              <div className="text-center">
                <img
                  src={walletSVG}
                  alt="Lower Your Payment"
                  className="mx-auto mb-6"
                />

                <h3 className="heading-five">Consolidate Debt</h3>
              </div>

              <div className="mb-6 text-center ">
                <div className="border-t-2 border-solid border-transparent"></div>
                <p className="mx-auto mb-0 w-9/12 py-[15px] text-typography-heading md:w-full md:py-[5px]">
                  Consolidate Debt Stop paying so much in interest. Start making
                  just one single, low-interest payment.
                </p>

                <div className="relative flex items-center justify-center py-[5px] text-center md:py-[8px]">
                  <div className="mr-0 max-w-[25%] flex-grow border-t border-[#001F3D] opacity-30"></div>
                  <span className=" block py-[5px] px-[13px] text-sm font-bold capitalize text-[#001F3D] md:py-[5px] ">
                    good for
                  </span>
                  <div className="ml-0 max-w-[25%] flex-grow border-t border-[#001F3D] opacity-30"></div>
                </div>
                <div className="relative flex items-center justify-center py-[5px] text-center md:justify-start md:py-[8px]">
                  <div className="mr-0  hidden  flex-grow border-t border-transparent opacity-30 md:visible md:!block md:max-w-[8%]"></div>
                  <span className=" block py-[5px] px-[13px] text-sm font-bold capitalize text-[#001F3D] md:py-[5px] ">
                    <ul className="styled-list-checkmark text-color-[#001F3D] text-typography-heading">
                      <li>Eliminating high-interest debt</li>
                      <li>Improving your credit score</li>
                      <li>Rolling your debt into one loan</li>
                    </ul>
                  </span>
                  {/* <div className="ml-0 max-w-[25%] flex-grow border-t border-[#001F3D] opacity-30"></div> */}
                </div>
              </div>

              <ButtonSolid
                href="/refinance/"
                text="Get Started Now!"
                className="!text-md mx-auto w-9/12"
              />
            </div>
            <div className="flex flex-col justify-between justify-between rounded-3xl bg-secondary-500/60 px-2 py-8 shadow-4xl">
              <div className="text-center">
                <img
                  src={trendSVG}
                  alt="Lower Your Payment"
                  className="mx-auto mb-6"
                />

                <h3 className="heading-five">Lower Your Payment</h3>
              </div>

              <div className="mb-6 text-center ">
                <div className="border-t-2 border-solid border-transparent pb-[8px]"></div>
                <p className="mx-auto mb-0 w-9/12 py-[15px] text-typography-heading md:w-full md:py-[5px]">
                  Make your biggest monthly expense easier to manage and put
                  extra money in your pocket.
                </p>
                <div className="relative flex items-center justify-center py-[5px] text-center md:py-[8px]">
                  <div className="mr-0 max-w-[25%] flex-grow border-t border-[#001F3D] opacity-30"></div>
                  <span className=" block py-[5px] px-[13px] text-sm font-bold capitalize text-[#001F3D] md:py-[5px] ">
                    good for
                  </span>
                  <div className="ml-0 max-w-[25%] flex-grow border-t border-[#001F3D] opacity-30"></div>
                </div>
                <div className="relative flex items-center justify-center py-[5px] text-center md:justify-start md:py-[8px]">
                  <div className="mr-0  hidden  flex-grow border-t border-transparent opacity-30 md:visible md:!block md:max-w-[8%]"></div>
                  <span className=" block py-[5px] px-[13px] text-sm font-bold capitalize text-[#001F3D] md:py-[5px] ">
                    <ul className="styled-list-checkmark text-color-[#001F3D] text-typography-heading">
                      <li>Gaining extra cash flow</li>
                      <li>Applying the savings to debt</li>
                      <li>Becoming mortgage-free faster!</li>
                    </ul>
                  </span>
                  {/* <div className="ml-0 max-w-[25%] flex-grow border-t border-[#001F3D] opacity-30"></div> */}
                </div>
              </div>

              <ButtonSolid
                href="/refinance/"
                text="Get Started Now!"
                className="!text-md mx-auto w-9/12"
              />
            </div>
          </div>
        </div>
      </section>

      {/* Mortgage Rate Section */}

      <RateOptions className="mb-20 bg-gradient-to-r from-[#ECF1F8] to-[#ECF1F8] md:mb-16 md:p-[56px] " />

      {/* Payment calac section */}
      <section className="mb-20 md:mb-26">
        <div className="container">
          <header className="mb-10 text-center md:mb-14">
            <h2>Estimate your payment.</h2>
          </header>

          <iframe
            src="https://mortgage-tools.flywheelsites.com/home-mortgage-calculator/"
            width="100%"
            height="100%"
            title="Mortgage Calculator"
            frameBorder="0"
            className="h-[1100px] md:h-[700px]"
          ></iframe>
        </div>
      </section>
      <Testimonials />
      <ValueProps />

      <div className="container">
        <section className="mb-20 rounded-3xl bg-primary-900 py-20 px-4 md:mb-32">
          <div className="mx-auto max-w-[688px] text-center">
            <h2 className="mb-4 text-white">
              Get in Touch With Our Team Today!
            </h2>
            <p className="text-primary-50">
              We’re happy to connect with you and answer any questions you might
              have. Reach our today!
            </p>
            <div className="flex flex-wrap items-center justify-center space-y-4 md:flex-nowrap md:space-y-0 md:space-x-4">
              <ButtonSolid
                href="/home-purchase/"
                text="Apply Now!"
                className="w-full !text-lg md:w-auto md:min-w-[200px]"
              />
              <ButtonSolid
                href="tel:866-299-1600"
                text="(866) 299-1600"
                altStyle={2}
                className="w-full !text-lg md:w-auto md:min-w-[200px]"
              />
            </div>
          </div>
        </section>
      </div>
    </Layout>
  );
};

export const data = graphql`
  {
    openGraphImage: file(
      relativePath: { eq: "Open Graph/Facebook/Homepage.jpg" }
    ) {
      publicURL
    }
    twitterOpenGraphImage: file(
      relativePath: { eq: "Open Graph/Twitter/Homepage.jpg" }
    ) {
      publicURL
    }
  }
`;

export default Page;
